import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FlashCard from './FlashCard';
import FlashCardActions from './FlashCardActions';
import GoodWork from './GoodWork';
import GlassGoodWork from './GlassGoodWork';
import CardEditor from '../builder/CardEditor';
import CardMover from '../mover/CardMover';
import _ from 'lodash';
import { fetchConfig } from '../../util/constants';
import { updateCardFrontend } from '../../util/cardUpdater';
import ToastStar from '../misc/ToastStar';
import ToastUnstar from '../misc/ToastUnstar';
import GamepadController from './GamepadController';

export default class FlashCardStudy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initializedDebounce: false,
            debounced: null,
            isFlipped: false,
            currentCard: {},
            currentType: null,
            showImageViewer: false,
            showCardEditor: false,
            showCardMover: false,
            showOnboarding: false,
            onboardingStep: 1,
            multichoiceSelected: false
        }
        this.cardRef = React.createRef();
    }

    componentDidMount() {
        document.body.classList.add('study');
        document.body.classList.remove('overflow-hidden');
        this.checkSetDefaultActiveStudyProfile();
        this.checkFetchCards();
        this.props.fetchUserStudySettings();
        this.initializedDebounce();
        this.props.receiveActiveStudySession({
            deck: this.props.deck?.name ? this.props.deck?.name : '',
            deckUUID: this.props.deck?.uuid ? this.props.deck?.uuid : '',
            type: "",
            again: 0,
            wrong: 0,
            right: 0,
            easy: 0,
            sessionTime: Date.now()
        });

        // if (this.checkOnboardingParameter()) {
        //     localStorage.setItem('showOnboarding', false);
        //     this.setState({showOnboarding: true});
        //     this.renderOnboardingContent();
        // }
        if (this.props.currentCard?.model === 'multichoice') {
            this.addMultichoiceEventListeners();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.currentCard?.model === 'multichoice') {
            if ((prevState.isFlipped !== this.state.isFlipped) && !this.state.isFlipped) {
                this.removeMultichoiceEventListeners();
                this.addMultichoiceEventListeners();
            }
        }
    }

    componentWillUnmount() {
        this.removeKeyBinds();
        document.body.classList.remove('study');
        document.body.classList.remove('good-work-screen');
        this.props.setActiveStudyProfile({});
        this.props.clearCards();
        this.props.clearPastCards();
        this.removeMultichoiceEventListeners();
    }

    openImageViewer = () => {
        this.setState({ showImageViewer: true });
    };

    closeImageViewer = () => {
        this.setState({ showImageViewer: false });
    };

    renderOnboardingContent = () => {
        if (this.state.onboardingStep === 1) {
            setTimeout(() => {
                const guide1 = document.getElementById('guide-1');
                guide1?.classList.add('fade-in');
            }, 3750);
        }
        if (this.state.onboardingStep === 2) {
            setTimeout(() => {
                const guide2 = document.getElementById('guide-2');
                guide2?.classList.add('fade-in');
            }, 250);
        }
        if (this.state.onboardingStep === 3) {
            setTimeout(() => {
                const guide3 = document.getElementById('guide-3');
                guide3?.classList.add('fade-in');
            }, 250);
        }
    }

    checkOnboardingParameter() {
        const urlObj = new URL(window.location.href);
        if (urlObj.hash?.includes('onboarding=true')) {
            return true;
        }
        return false;
    }

    setMultichoiceSelected = (value) => {
        this.setState({multichoiceSelected: value});
    }

    modifySessionStats = (response) => {
        const responseMap = {
            "minus2": "again",
            "minus1": "wrong",
            "plus1": "right",
            "plus2": "easy"
        }
        if (this.props.activeStudySession) {
            let currentStats = {...this.props.activeStudySession};
            currentStats[responseMap[response]] = currentStats[responseMap[response]] + 1;
            this.props.receiveActiveStudySession(currentStats);
        }
    }

    initializedDebounce = () => {
        if (!this.state.initializedDebounce) {
            const debounced = _.debounce(this.keyBinds, 50, { 'leading': true, 'trailing': false })
            window.addEventListener('keydown', debounced)
            this.setState({ initializedDebounce: true, debounced })
        }
    }

    removeKeyBinds = () => {
        if (this.state.initializedDebounce) {
            window.removeEventListener('keydown', this.state.debounced);
        }
    }

    reinitializedKeyBinds = () => {
        if (this.state.initializedDebounce) {
            window.addEventListener('keydown', this.state.debounced)
        }
    }

    // safeguards against the edge case of reaching a deck with no defined active study profile
    checkSetDefaultActiveStudyProfile = () => {
        if (_.isEmpty(this.props.activeStudyProfile) ||
            this.props.activeStudyProfile.deckId !== this.props.deckId) {
            this.props.setActiveStudyProfile({
                deckId: this.props.deckId,
                new: this.props.progress.new,
                due: this.props.progress.due
            });
        }
    }

    checkFetchCards = () => {
        if (this.shouldFetchMore('new')) {
            const numNewToFetch = Math.min(this.props.activeStudyProfile.new, fetchConfig.batchSize);
            const tags = this.props.selectedTags;
            const options = {
                count: numNewToFetch,
                type: 'new',
                tags: tags,
                taggingStrategy: this.props.taggingStrategy,
            }
            this.props.fetchCards(this.props.deckId, options);
        }
        if (this.shouldFetchMore('due')) {
            const numDueToFetch = Math.min(this.props.activeStudyProfile.due, fetchConfig.batchSize);
            const tags = this.props.selectedTags;
            const options = {
                count: numDueToFetch,
                type: 'due',
                tags: tags,
                taggingStrategy: this.props.taggingStrategy,
            }
            this.props.fetchCards(this.props.deckId, options);
        }
        // Addresses the issue where the frontend gets stuck with only strictly due cards,
        // resulting in new cards being shown before flexibly due cards.
        // Solution: fetch a batch of flexibly due cards if they run too low
        if (this.shouldFetchMoreFlexibleCards()) {
            const numDueToFetch = Math.min(this.props.activeStudyProfile.due, 10);
            const tags = this.props.selectedTags;
            const options = {
                count: numDueToFetch,
                type: 'flexible_due',
                tags: tags,
                taggingStrategy: this.props.taggingStrategy,
            }
            this.props.fetchCards(this.props.deckId, options);
        }
    }

    shouldFetchMore = (type) => {
        if (this.props.cards[type] == undefined) return true;
        return (this.props.cards[type].length <= fetchConfig.threshold)
            && (this.props.cards[type].length < this.props.activeStudyProfile[type]);
    }

    shouldFetchMoreFlexibleCards = () => {
        if (this.props.cards['due'] == undefined) return false;
        return this.isBelowFlexibleThreshold() && !this.isEqualToActiveStudyProfile('due');
    }

    isBelowFlexibleThreshold = () => {
        const dueCards = this.props.cards['due'];
        const flexibleDueCards = dueCards.filter(card => card.interval >= 1440);
        return (dueCards.length > fetchConfig.threshold) && (flexibleDueCards.length <= 2);
    }

    isEqualToActiveStudyProfile = (type) => {
        return this.props.cards[type].length == this.props.activeStudyProfile[type]
    }

    flipCard = () => {
        this.setState({ isFlipped: true }, () => {
            this.handleMultichoiceHighlighting();
        });
        if (this.state.showOnboarding) {
            const newStep = this.state.onboardingStep + 1;
            this.setState({onboardingStep: newStep}, () => {
                this.renderOnboardingContent();
            });
        }
    }

    unflipCard = () => {
        (document.querySelector('.card__text') || {}).scrollTop = 0;
        (document.querySelector('.qbank-content > .left') || {}).scrollTop = 0;
        (document.querySelector('.qbank-content > .right') || {}).scrollTop = 0;
        this.setState({ isFlipped: false }, () => {
            this.handleMultichoiceReseting();
        });
    }

    selectMultichoiceAnswerHandlers = [
        () => this.selectMultichoiceAnswer(0),
        () => this.selectMultichoiceAnswer(1),
        () => this.selectMultichoiceAnswer(2),
        () => this.selectMultichoiceAnswer(3),
        () => this.selectMultichoiceAnswer(4)
    ]

    addMultichoiceEventListeners = () => {
        this.selectMultichoiceAnswerHandlers.forEach((handler, index) => {
            const element = document.getElementById(`choice-${index}`);
            if (element) {
                element.addEventListener('click', handler);
            }
        });
    }

    removeMultichoiceEventListeners = () => {
        this.selectMultichoiceAnswerHandlers.forEach((handler, index) => {
            const element = document.getElementById(`choice-${index}`);
            if (element) {
                element.removeEventListener('click', handler);
            }
        });
    }

    selectMultichoiceAnswer = (choice) => {
        const choiceEl = document.getElementById('choice-' + choice + '-input');
        this.setMultichoiceSelected(true);
        if (choiceEl) {
            choiceEl.checked = true;
            this.flipCard();
        }
    }

    handleMultichoiceHighlighting = () => {
        if (this.props.currentCard?.model === 'multichoice') {
            const multichoiceOptions = document.getElementById('multichoice-options');
            const correctAnswer = this.props.currentCard.multi_choice_answer;
            const correctChoiceEl = document.getElementById(`choice-${correctAnswer}`);
            const checkedChoiceEl = document.querySelector('input[name="multichoice"]:checked')?.parentElement;
            correctChoiceEl?.classList.add('multichoice-correct-answer');
            multichoiceOptions?.classList.add('answer-revealed');
            if (correctChoiceEl !== checkedChoiceEl) {
                checkedChoiceEl?.classList.add('multichoice-incorrect-answer');
            }
        }
    }

    handleMultichoiceReseting = () => {
        if (this.props.currentCard?.model === 'multichoice') {
            // Remove correct answer highlighting
            document.getElementById(`choice-0`)?.classList.remove('multichoice-correct-answer');
            document.getElementById(`choice-1`)?.classList.remove('multichoice-correct-answer');
            document.getElementById(`choice-2`)?.classList.remove('multichoice-correct-answer');
            document.getElementById(`choice-3`)?.classList.remove('multichoice-correct-answer');
            document.getElementById(`choice-4`)?.classList.remove('multichoice-correct-answer');

            // Mark answer as not revealed
            const multichoiceOptions = document.getElementById('multichoice-options');
            multichoiceOptions?.classList.remove('answer-revealed');

            // Uncheck radio button
            const checkedInputEl = document.querySelector('input[name="multichoice"]:checked');
            if (checkedInputEl) {
                checkedInputEl.checked = false;
                checkedInputEl?.parentElement.classList.remove('multichoice-incorrect-answer')
            }
        }
    }

    undoResponse = () => {
        if (_.isEmpty(this.props.prevCardInfo)) {
            toast("No Undos Remaining", {
                position: "bottom-left",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        const type = this.props.prevCardInfo.type;
        const card = this.props.prevCardInfo.card;

        const dueContainsCard = this.props.cards['due'].some((existingCard) => {
            if (existingCard.id == card.id) return true;
        })
        let activeStudyProfile = _.clone(this.props.activeStudyProfile);
        if (type === 'new') {
            activeStudyProfile['new'] += 1;
            if (dueContainsCard) activeStudyProfile['due'] -= 1;
        } else {
            if (!dueContainsCard) activeStudyProfile['due'] += 1;
        }
        this.props.setActiveStudyProfile(activeStudyProfile);
        this.props.undoCardResponse({ deckId: this.props.deck.id, ...this.props.prevCardInfo });
        this.unflipCard();
        this.removeMultichoiceEventListeners();
        this.addMultichoiceEventListeners();
        toast("Undo Response", {
            position: "bottom-left",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    }

    renderRatingFeedback = (response) => {
        const ratingMap = {
            'minus2': "Again",
            'minus1': "Wrong",
            'plus1': "Right",
            'plus2': "Easy"
        }
        let feedbackTarget = document.querySelector('.button-bar_items');
        const childDiv = document.createElement("div");
        childDiv.classList.add("rating-feedback");
        childDiv.classList.add("rating-feedback--" + response);
        childDiv.textContent = ratingMap[response];
        feedbackTarget?.appendChild(childDiv);
        Promise.resolve().then(() => {
            childDiv.classList.add("show");
        });
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        delay(500).then(() => {
            childDiv.classList.add("hide");
            return new Promise(resolve => setTimeout(resolve, 250));
        }).then(() => {
            feedbackTarget?.removeChild(childDiv);
        });
    }

    handleButtonResponse = (e) => {
        (document.querySelector('.card__text') || {}).scrollTop = 0;
        const response = e.target.getAttribute('data-answer');
        this.handleResponse(response);
    }

    handleResponse = (response) => {
        if (_.isEmpty(this.props.currentCard)) return;

        if (this.state.showOnboarding) {
            const newStep = this.state.onboardingStep + 1;
            this.setState({onboardingStep: newStep}, () => {
                this.renderOnboardingContent();
            });
        }

        this.modifySessionStats(response);

        const currentType = this.props.currentType;
        this.props.receivePastCard({ type: currentType, card: _.cloneDeep(this.props.currentCard) });

        let updatedCard;
        // Passes different userStudySettings for multichoice cards
        if (this.props.currentCard?.model === 'multichoice') {
            const multichoiceStudySettings = {
                multiple: 4,
                num_responses: 4,
                // 1d, 14d, 56d
                steps: [1440, 20160, 80640]
            }
            updatedCard = updateCardFrontend(_.cloneDeep(this.props.currentCard), response, multichoiceStudySettings);
        } else {
            updatedCard = updateCardFrontend(_.cloneDeep(this.props.currentCard), response, this.props.userStudySettings);
        }

        // TODO: Improve failed update behavior
        this.props.updateCardResponse(updatedCard, response).then(() => {
            this.props.fetchPercentMastery(this.props.deck.id);
            this.checkFetchCards();
        });
        if (!this.isDueToday(updatedCard)) {
            this.props.shiftCard(this.props.deck.id, currentType);

            let activeStudyProfile = _.clone(this.props.activeStudyProfile);
            activeStudyProfile[currentType] -= 1;
            this.props.setActiveStudyProfile(activeStudyProfile);
        } else {
            this.props.shiftCard(this.props.deck.id, currentType);
            this.props.addCardToQueue(this.props.deck.id, 'due', updatedCard);

            if (currentType === 'new') {
                let activeStudyProfile = _.clone(this.props.activeStudyProfile);
                activeStudyProfile['new'] -= 1;
                activeStudyProfile['due'] += 1;
                this.props.setActiveStudyProfile(activeStudyProfile);
            }
        }
        this.unflipCard();
    }

    printCard = (card) => {
        // console.log("####################");
        // console.log(`id: ${card.id}`);
        // console.log(`state: ${card.state}`);
        // console.log(`level: ${card.level}`);
        // console.log(`interval: ${card.interval}`);
        // console.log(`last_practiced_at: ${card.last_practiced_at}`);
        // console.log("####################");
    }

    isDueToday = (card) => {
        const nextPracticeDueAt = card.next_practice_due_at;
        // TODO: check if this messes up around 5pm PST (0 UTC)
        return moment(nextPracticeDueAt).isSameOrBefore(moment(), 'day');
    }

    doneStudying = () => {
        return _.isEmpty(this.props.currentCard) && !this.props.loading;
    }

    keyBinds = (e) => {
        if (document.activeElement.id === "report-input") return;
        if ((e.code == "Space" || e.keyCode == 32 || e.key == " ") && !this.state.isFlipped) {
            if (document.activeElement.classList.contains('flashcard-input')) return;
            const checkedChoiceEl = document.querySelector('input[name="multichoice"]:checked')?.parentElement;
            e.preventDefault();
            this.closeImageViewer();
            if (this.props.currentCard?.model === 'multichoice') {
                if (checkedChoiceEl) {
                    this.flipCard();
                }
            } else {
                this.flipCard();
            }
        } else if ((e.code == "Space" || e.keyCode == 32 || e.key == " ") && this.state.isFlipped) {
            e.preventDefault();
            this.closeImageViewer();
            // Multichoice
            if (this.props.currentCard.model === 'multichoice') {
                const correctAnswer = this.props.currentCard.multi_choice_answer;
                const correctChoiceEl = document.getElementById(`choice-${correctAnswer}`);
                const checkedChoiceEl = document.querySelector('input[name="multichoice"]:checked')?.parentElement;
                if (correctChoiceEl && checkedChoiceEl) {
                    // Correct
                    if (correctChoiceEl === checkedChoiceEl) {
                        this.handleResponse('plus1');
                    // Wrong
                    } else {
                        this.handleResponse('minus2');
                    }
                }
            // Flashcard
            } else {
                this.handleResponse('plus1');
                this.renderRatingFeedback('plus1');
            }
        } else if (!this.state.isFlipped && (e.code == "Digit1" || e.keyCode == 49 || e.key == "1")) {
            this.selectMultichoiceAnswer(0);
        } else if (!this.state.isFlipped && (e.code == "Digit2" || e.keyCode == 50 || e.key == "2")) {
            this.selectMultichoiceAnswer(1);
        } else if (!this.state.isFlipped && (e.code == "Digit3" || e.keyCode == 51 || e.key == "3")) {
            this.selectMultichoiceAnswer(2);
        } else if (!this.state.isFlipped && (e.code == "Digit4" || e.keyCode == 52 || e.key == "4")) {
            this.selectMultichoiceAnswer(3);
        } else if (!this.state.isFlipped && (e.code == "Digit5" || e.keyCode == 53 || e.key == "5")) {
            this.selectMultichoiceAnswer(4);
        } else if (this.props.currentCard?.model !== "multichoice" && (e.code == "Digit1" || e.keyCode == 49 || e.key == "1") && this.state.isFlipped) {
            this.closeImageViewer();
            if (this.props.userStudySettings?.num_responses === 4) {
                this.renderRatingFeedback('minus2');
                this.handleResponse('minus2');
            } else {
                this.renderRatingFeedback('minus1');
                this.handleResponse('minus1');
            }
        } else if (this.props.currentCard?.model !== "multichoice" && (e.code == "Digit2" || e.keyCode == 50 || e.key == "2") && this.state.isFlipped) {
            this.closeImageViewer();
            if (this.props.userStudySettings?.num_responses === 2 || this.props.userStudySettings?.num_responses === 3) {
                this.renderRatingFeedback('plus1');
                this.handleResponse('plus1');
            } else {
                this.renderRatingFeedback('minus1');
                this.handleResponse('minus1');
            }
        } else if (this.props.currentCard?.model !== "multichoice" && (e.code == "Digit3" || e.keyCode == 51 || e.key == "3") && this.state.isFlipped) {
            this.closeImageViewer();
            if (this.props.userStudySettings?.num_responses === 3) {
                this.renderRatingFeedback('plus2');
                this.handleResponse('plus2');
            } else if (this.props.userStudySettings?.num_responses === 4) {
                this.renderRatingFeedback('plus1');
                this.handleResponse('plus1');
            }
        } else if (this.props.currentCard?.model !== "multichoice" && (e.code == "Digit4" || e.keyCode == 52 || e.key == "4") && this.state.isFlipped && this.props.userStudySettings?.num_responses === 4) {
            this.closeImageViewer();
            this.renderRatingFeedback('plus2');
            this.handleResponse('plus2');
        } else if ((e.code == "KeyF" || e.keyCode == 70 || e.key == "f") && this.state.isFlipped) {
            this.closeImageViewer();
            this.unflipCard();
        } else if ((e.code == "KeyF" || e.keyCode == 70 || e.key == "f") && !this.state.isFlipped) {
            if (document.activeElement.classList.contains('flashcard-input')) return;
            e.preventDefault();
            this.closeImageViewer();
            this.flipCard();
        } else if (e.code == "KeyZ" || e.keyCode == 90 || e.key == "z") {
            this.closeImageViewer();
            this.undoResponse();
        } else if (!e.metaKey && !e.ctrlKey && (e.code == "KeyC" || e.keyCode == 67 || e.key == "c")) {
            if (!(this.props.user?.curator || this.props.user?.admin)) return;
            this.closeImageViewer();
            this.showCardMover();
        } else if ((e.code == "KeyS" || e.keyCode == 83 || e.key == "s")) {
            if (!this.props.currentCard.editable) return;
            if (this.props.currentCard.tag_list.includes('Starred')) {
                this.props.untagCard(this.props.currentCard.id, 'Starred');
                toast(<ToastUnstar />, {
                    position: "bottom-left",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                this.props.tagCard(this.props.currentCard.id, 'Starred');
                toast(<ToastStar />, {
                    position: "bottom-left",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else if ((e.code == "KeyE" || e.keyCode == 69 || e.key == "e")) {
            if (this.props.currentCard.editable) {
                if (this.props.currentCard.model === 'multichoice') {
                    if (this.props.user.admin || this.props.user.curator) {
                        this.closeImageViewer();
                        this.editCard();
                    } else {
                        return;
                    }
                } else {
                    this.closeImageViewer();
                    this.editCard();
                }
            };
        } else if ((e.code == "Delete" || e.keyCode == 46 || e.key == "Delete") || (e.code == "Backspace" || e.keyCode == 8 || e.key == "Backspace")) {
            // this.suspendCard();
        } else if (e.key === "Esc" || e.keyCode === 27 || e.key == "Escape") {
            this.closeImageViewer();
        } else if ((e.code == "BracketRight" || e.keyCode == 221 || e.key == "]")) {
            // Only allow tagging when you own the deck the card is in
            if (!this.props.currentCard.editable) return;

            if (this.props.currentCard.tag_list.includes('High Yield')) {
                this.props.untagCard(this.props.currentCard.id, 'High Yield');
                toast.success('Untagged High Yield', {
                    position: "bottom-left",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                this.props.tagCard(this.props.currentCard.id, 'High Yield');
                toast.success('Tagged High Yield', {
                    position: "bottom-left",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else if ((e.code == "BracketLeft" || e.keyCode == 219 || e.key == "[")) {
            if (!this.props.currentCard.editable) return;

            if (this.props.currentCard.tag_list.includes('Standard')) {
                this.props.untagCard(this.props.currentCard.id, 'Standard');
                toast.success('Untagged Standard', {
                    position: "bottom-left",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                this.props.tagCard(this.props.currentCard.id, 'Standard');
                toast.success('Tagged Standard', {
                    position: "bottom-left",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    suspendCard = () => {
        this.props.suspendCard(this.props.currentCard.id);
        this.cleanUpSuspendedCard();
    }

    cleanUpSuspendedCard = () => {
        const currentType = this.props.currentType;

        // Remove suspended card from study session stats
        let activeStudyProfile = _.clone(this.props.activeStudyProfile);
        activeStudyProfile[currentType] -= 1;
        this.props.setActiveStudyProfile(activeStudyProfile);

        // Remove suspended card from study queue
        this.props.shiftCard(this.props.deck.id, currentType);

        // Check if we should fetch more cards after cleaning out suspended card
        this.checkFetchCards();

        this.unflipCard();
    }

    editCard = () => {
        this.removeKeyBinds();
        this.setState({ showCardEditor: true });
    }

    hideCardEditor = () => {
        this.reinitializedKeyBinds();
        this.setState({ showCardEditor: false });
    }

    showCardMover = () => {
        this.removeKeyBinds();
        this.setState({ showCardMover: true });
    }

    hideCardMover = () => {
        this.reinitializedKeyBinds();
        this.setState({ showCardMover: false });
    }

    handleGamePadPress = (bIndex) => {
        if (bIndex === 0) { // B
            if (this.props.userProperties.studyControllerOrientation === 'v') {
                if (this.state.isFlipped) {
                    this.closeImageViewer();
                    this.renderRatingFeedback('minus1');
                    this.handleResponse('minus1');
                }
            } else {
                this.closeImageViewer();
                if (!this.state.isFlipped) {
                    this.flipCard();
                } else {
                    this.renderRatingFeedback('plus1');
                    this.handleResponse('plus1');
                }
            }
        }
        if (bIndex === 1) { // A
            if (this.props.userProperties.studyControllerOrientation === 'v') {
                if (this.state.isFlipped) {
                    if (this.props.userStudySettings?.num_responses === 4) {
                        this.closeImageViewer();
                        this.renderRatingFeedback('minus2');
                        this.handleResponse('minus2');
                    }
                }
            } else {
                if (this.state.isFlipped) {
                    this.closeImageViewer();
                    this.renderRatingFeedback('minus1');
                    this.handleResponse('minus1');
                }
            }
        }
        if (bIndex === 2) { // X
            if (this.props.userProperties.studyControllerOrientation === 'v') {
                this.closeImageViewer();
                if (!this.state.isFlipped) {
                    this.flipCard();
                } else {
                    this.renderRatingFeedback('plus1');
                    this.handleResponse('plus1');
                }
            } else {
                if (this.state.isFlipped) {
                    if (this.props.userStudySettings?.num_responses === 4 || this.props.userStudySettings?.num_responses === 3) {
                        this.closeImageViewer();
                        this.renderRatingFeedback('plus2');
                        this.handleResponse('plus2');
                    }
                }
            }
        }
        if (bIndex === 3) { // Y
            if (this.props.userProperties.studyControllerOrientation === 'v') {
                if (this.state.isFlipped) {
                    if (this.props.userStudySettings?.num_responses === 4 || this.props.userStudySettings?.num_responses === 3) {
                        this.closeImageViewer();
                        this.renderRatingFeedback('plus2');
                        this.handleResponse('plus2');
                    }
                }
            } else {
                if (this.state.isFlipped) {
                    if (this.props.userStudySettings?.num_responses === 4) {
                        this.closeImageViewer();
                        this.renderRatingFeedback('minus2');
                        this.handleResponse('minus2');
                    }
                }
            }
        }
        if (bIndex === 4) { // L
            this.undoResponse();
        }
        if (bIndex === 5) { // R
            if (this.state.isFlipped) {
                this.unflipCard();
            } else {
                this.flipCard();
            }
        }
        if (bIndex === 8) { // Select
            
        }
        // Scroll
        if (this.props.userProperties.studyControllerOrientation === 'v') {
            if (bIndex === 101) { 
                (document.querySelector('.card__text') || {}).scrollTop -= 10;
            }
            if (bIndex === 100) {
                (document.querySelector('.card__text') || {}).scrollTop += 10;
            }
        } else {
            if (bIndex === 102) { 
                (document.querySelector('.card__text') || {}).scrollTop -= 10;
            }
            if (bIndex === 103) {
                (document.querySelector('.card__text') || {}).scrollTop += 10;
            }
        }
    }

    renderMainContent() {
        if (this.doneStudying()) {
            return (
                <>
                    {(this.props.theme === 'theme-glass' && this.props.user.group !== 'thieme') ? <GlassGoodWork userStudySettings={this.props.userStudySettings} activeStudySession={this.props.activeStudySession} /> : <GoodWork userStudySettings={this.props.userStudySettings} activeStudySession={this.props.activeStudySession} />}
                </>
            );
        } else {
            return (
                <>
                    <FlashCard
                        card={_.defaultTo(this.props.currentCard, {})}
                        currentType={this.props.currentType}
                        user={this.props.user}
                        loading={this.props.loading}
                        isFlipped={this.state.isFlipped}
                        unflipCard={this.unflipCard}
                        isPrevCard={!_.isEmpty(this.props.prevCardInfo)}
                        undoResponse={this.undoResponse}
                        removeKeyBinds={this.removeKeyBinds}
                        reinitializedKeyBinds={this.reinitializedKeyBinds}
                        tagCard={this.props.tagCard}
                        untagCard={this.props.untagCard}
                        editCard={this.editCard}
                        suspendCard={this.suspendCard}
                        cloneCard={this.showCardMover}
                        activeStudyProfile={this.props.activeStudyProfile}
                        userStudySettings={this.props.userStudySettings}
                        userProperties={this.props.userProperties}
                        showImageViewer={this.state.showImageViewer}
                        openImageViewer={this.openImageViewer}
                        closeImageViewer={this.closeImageViewer}
                        activeStudySession={this.props.activeStudySession}
                        receiveActiveStudySession={this.props.receiveActiveStudySession}
                    />
                    {this.state.showOnboarding && this.state.onboardingStep === 1 && <div id="guide-1" className="guide">
                        Think of the answer, then press <div className='badge badge--rating badge--rating--space'>SPACEBAR</div> or click Reveal Answer
                    </div>}
                    {this.state.showOnboarding && this.state.onboardingStep === 2 && <div id="guide-2" className='guide'>
                        Now rate the difficulty of this card
                        <div className='ratings'>
                            <div className='ratings_rating'>
                                <div className='badge badge--rating'>1</div>
                                Again
                            </div>
                            <div className='ratings_rating'>
                                <div className='badge badge--rating'>2</div>
                                Wrong
                            </div>
                            <div className='ratings_rating'>
                                <div className='badge badge--rating'>3</div>
                                Right
                            </div>
                            <div className='ratings_rating'>
                                <div className='badge badge--rating'>4</div>
                                Easy
                            </div>
                        </div>
                    </div>}
                    {this.state.showOnboarding && this.state.onboardingStep === 3 && <div id="guide-3" className='guide'>
                        <span className='m-b-1 d-block'>Great! We'll schedule that card's next review based on your rating.</span>
                        <span>Let's try one more card.</span>
                    </div>}
                    <FlashCardActions
                        card={this.props.currentCard}
                        isFlipped={this.state.isFlipped}
                        flipCard={this.flipCard}
                        handleButtonResponse={this.handleButtonResponse}
                        userStudySettings={this.props.userStudySettings}
                        multichoiceSelected={this.state.multichoiceSelected}
                        setMultichoiceSelected={this.setMultichoiceSelected}
                    />
                </>
            )
        }
    }

    render() {
        return (
            <div className="layout layout--study">
                <div className="layout--study__main">
                    {this.renderMainContent()}
                </div>
                <GamepadController
                    handleButtonPress={this.handleGamePadPress}
                />
                {this.state.showCardEditor &&
                    <CardEditor
                        user={this.props.user}
                        card={_.defaultTo(this.props.currentCard, {})}
                        hideModal={this.hideCardEditor}
                        updateCardContent={this.props.updateCardContent}
                    />
                }
                {this.state.showCardMover &&
                    <CardMover
                        card={_.defaultTo(this.props.currentCard, {})}
                        hide={this.hideCardMover}
                    />
                }
            </div>
        )
    }
}
